@use '@angular/material' as mat;
@use 'm3-theme.scss';
@include mat.core();

html {
  @include mat.all-component-themes(m3-theme.$light-theme);
}

@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/Roboto-Light.ttf");
}
* {
  box-sizing: border-box;
  font-family: Roboto;
}

html, body { height: 100%; }
body { margin: 0; }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* Spacer for flex */
.flex-spacer {
  flex: 1 1 auto;
}

.page-margin-1 {
  margin: 1rem;
}
.page-margin-2 {
  margin: 1rem 2rem;
}
.page-margin-3 {
  margin: 1rem 3rem;
}

/* Round images */
.round-img-32 {
    border-radius: 50%;
    width: 32px;
    height: 32px;
}
.round-img-64 {
    border-radius: 50%;
    width: 64px;
    height: 64px;
}
.round-img-96 {
    border-radius: 50%;
    width: 96px;
    height: 96px;
}
.round-img-128 {
    border-radius: 50%;
    width: 128px;
    height: 128px;
}

/* Global styles for serchable and paginated table */
.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .table-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    .table-action mat-icon:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

.table {
  width: 100%;

  tr.mat-row:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
  tr.mat-row mat-icon.action-icon {
    margin-top: 6px;
  }
  .actions-column {
    text-align: right;
    min-width: 70px;
    a {
      cursor: pointer;
      &:not(:first-child) { margin-left: .5rem; }
      &:hover mat-icon {
        opacity: 0.8;
      }
      mat-icon {
        opacity: 0.5;
      }
    }
  }
}

/* Spinner for buttons */
@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}